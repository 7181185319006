<template>
  <div>
    <!-- masterDaftarDokumen
    <br>
    {{masterDaftarDokumen}}
    <br>
    masterDaftarPersyaratan
    <br>
    {{masterDaftarPersyaratan}} -->
    <!-- <br>
    Selected Persyaratan
    <br>
    {{selectedPersyaratan}} -->
    <b-card title="Checklist Verifikasi" class="mb-2">
      <b-card-text>
        Daftar dokumen yang terverifikasi
      </b-card-text>

      <b-form-group v-slot="{ ariaDescribedby }">
        <div v-if="processing" class="d-flex justify-content-center m-2">
          <b-spinner label="Loading..." />
        </div>
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="selectedPersyaratan"
          :options="optionsPersyaratan"
          :aria-describedby="ariaDescribedby"
          stacked
          disabled="disabled"
          name="flavour-1"
        />
      </b-form-group>
      <b-button
        v-if="showReload"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mb-75"
        block
        @click="recheckPersyaratan"
      >
        Reload Hasil Verifikasi
      </b-button>
    </b-card>
  </div>
</template>
<script>
import emitter from '@/hap-sia/emitter'
import {
  BCard,
  BButton,
  BCardText,
  BFormGroup,
  BFormCheckboxGroup,
  BSpinner,
} from 'bootstrap-vue'
import { api } from '@/hap-sia/setup'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BFormGroup,
    BFormCheckboxGroup,
    BButton,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  // props: ['masterPermohonan', 'jenisPermohonanId', 'masterDaftarPersyaratan', 'masterDaftarDokumen'],
  props: ['biodataID', 'jenisPermohonanId'],
  data() {
    return {
      selectedPersyaratan: [],
      optionsPersyaratan: [],
      showReload: false,
      processing: false,
    }
  },
  async mounted() {
    if (!this.biodataID) {
      // do nothing
    } else {
      this.recheckPersyaratan()
    }

    emitter.on('VERIFICATION_UPDATED', () => {
      console.log('VERIFIKASI TELAH DILAKUKAN')
      this.recheckPersyaratan()
    })
    emitter.on('BIODATA_DOKUMEN_TERSIMPAN', () => {
      this.recheckPersyaratan()
    })
    emitter.on('BIODATA_DOKUMEN_DELETED', () => {
      this.recheckPersyaratan()
    })

    // this.masterDaftarPersyaratan.forEach(element => {
    //   this.optionsPersyaratan.push({
    //     value: element.jenis_dokumen_id,
    //     text: element.nama,
    //   })
    //   if (element.uploaded) {
    //     this.selectedPersyaratan.push(element.jenis_dokumen_id)
    //   }
    // })
  },
  methods: {
    async recheckPersyaratan() {
      try {
        this.processing = true
        this.showReload = false
        const results = await api().get(
          `permohonan/cek-verifikasi/${this.jenisPermohonanId}/${this.biodataID}`,
        )
        this.optionsPersyaratan.splice(0, this.optionsPersyaratan.length)
        this.selectedPersyaratan.splice(0, this.selectedPersyaratan.length)

        results.data.forEach(element => {
          element.daftar_dokumen.forEach(dok => {
            this.optionsPersyaratan.push({
              value: dok.id,
              text: element.jenis_dokumen.nama,
            })
            if (dok.verification_status === '1') {
              this.selectedPersyaratan.push(dok.id)
            }
          })
        })
        this.processing = false
        this.showReload = false
      } catch (error) {
        alert(error)
        this.processing = false
        this.showReload = true
      }
    },
  },
}
</script>
