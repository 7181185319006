import { getCacheableApi, api } from '@/hap-sia/setup'

const userData = JSON.parse(localStorage.getItem('userData'))

async function preloadMasterData(progressCallback) {
  const cacheApi = await getCacheableApi()
  progressCallback('Loading negara dan jenis kartu identitas')
  const step1 = await Promise.all([
    cacheApi.get('master/negara'),
    cacheApi.get('master/jenis-kartu-identitas'),
  ])

  progressCallback('Loading agama dan gender')
  const step2 = await Promise.all([
    cacheApi.get('master/agama'),
    cacheApi.get('master/gender'),
  ])

  progressCallback('Loading DPN/DPC dan jenis dokumen')
  const step3 = await Promise.all([
    cacheApi.get('master/struktur-organisasi'),
    cacheApi.get('master/jenis-dokumen'),
  ])

  progressCallback('Loading propinsi dan jenis alamat')
  const step4 = await Promise.all([
    cacheApi.get('master/propinsi'),
    cacheApi.get('master/jenis-alamat'),
  ])

  //   progressCallback('')
  //   const step22 = await Promise.all([
  //     // cacheApi.get('master/propinsi'),
  //     // cacheApi.get('master/jenis-dokumen'),
  //     // cacheApi.get('master/jenis-kartu-identitas'),
  //     // cacheApi.get('master/negara'),
  //     // cacheApi.get('master/gender'),
  //     // cacheApi.get('master/agama'),
  //     // cacheApi.get('master/struktur-organisasi'),
  //     // cacheApi.get('master/jenis-alamat'),
  //   ])
  return {
    masterNegara: step1[0].data,
    masterJenisKartuIdentitas: step1[1].data,
    masterAgama: step2[0].data,
    masterGender: step2[1].data,
    masterStrukturOrganisasi: step3[0].data,
    masterJenisDokumen: step3[1].data,
    masterPropinsi: step4[0].data,
    masterJenisAlamat: step4[1].data,
  }
}
function isRoleAllowed() {
  if (
    userData.default_role !== 'verifikator' &&
    userData.default_role !== 'admin' &&
    userData.default_role !== 'superadmin' &&
    userData.default_role !== 'user' &&
    userData.default_role !== 'member' &&
    userData.default_role !== 'operator'
  ) {
    return false
  }
  return true
}
async function getMyBiodataID() {
  const userResult = await api().get('user/me')
  if (!userResult) {
    return null
  }
  userData.biodata_id = userResult.data.biodata_id
  localStorage.setItem('userData', JSON.stringify(userData))
  return userResult.data.biodata_id
}

// check dulu apakah ada permohonan yang sedang diajukan
async function cekEksistingPermohonan(jenisPermohonanID, biodataID) {
  const eksistings = await api().get(
    `permohonan/cek-eksisting/${jenisPermohonanID}/${biodataID}`,
  )
  if (eksistings.data.length < 1) {
    return false
  }
  return true
}

async function getEksistingDraftPermohonan(jenisPermohonanID, biodataID) {
  const drafts = await api().post('permohonan/search', {
    jenis_permohonan_id: jenisPermohonanID,
    biodata_id: biodataID,
    is_draft: true,
  })
  if (drafts.data.length > 0) {
    return drafts.data[0]
  }
  const newDraft = await api().post('permohonan', {
    jenis_permohonan_id: jenisPermohonanID,
    biodata_id: biodataID,
    is_draft: true,
  })
  return newDraft.data
}

function newBiodata() {
  return {
    id: null,
    nama_lengkap: null,
    gelar_depan: null,
    gelar_belakang: null,
    agama_id: null,
    jenis_kartu_identitas_id: 1,
    nomor_kartu_identitas: null,
    gender_id: 1,
    tempat_lahir: null,
    tanggal_lahir: null,
    nomor_hp: null,
    email: null,
    nama_ktpa: null,
    warga_negara_id: 'id',
    pekerjaan: null,
    dpn_dpc_id: null,
    keahlian_lainnya: '',
    is_published: true,
    is_advokat: false,
    nia: null,
    anggota_sejak: null,
    tanggal_berakhir: null,
    is_pengakhiran: false,
    is_pengurus: false,
    is_kehormatan: false,
    created_by: null,
    tanggal_nia: null,
    daftar_alamat: [],
    daftar_dokumen: [],
  }
}

function test() {
  console.log('test')
}

export {
  preloadMasterData,
  isRoleAllowed,
  getMyBiodataID,
  cekEksistingPermohonan,
  getEksistingDraftPermohonan,
  newBiodata,
  test,
}
