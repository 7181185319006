<template>
  <b-sidebar
    id="sidebar-tanggal-akhir"
    ref="sidebarTanggalAkhir"
    title="Keanggotaan"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    right
    backdrop
    shadow
  >
    <div class="px-3 py-2">
      <!-- <p>
          {{localPermohonan}}
        </p> -->
      <b-form-group label="Tanggal Akhir Keanggotaan" label-for="tanggal-akhir">
        <flat-pickr
          id="tanggal-akhir"
          v-model="tanggalBerakhir"
          class="form-control"
        />
      </b-form-group>
      <div class="mb-1">
        &nbsp;&nbsp;.
      </div>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        :disabled="btnSimpanDisabled"
        @click="simpan"
      >
        <b-spinner v-show="btnSimpanSpinnerShow" small type="grow" />
        <span class="sr-only">Loading...</span>
        Simpan
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-warning"
        class="ml-2"
        @click="cancel"
      >
        Batal
      </b-button>
    </div>
  </b-sidebar>
</template>
<script>
import { BSidebar, BFormGroup, BButton, BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import emitter from '@/hap-sia/emitter'
import { api } from '@/hap-sia/setup'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BSidebar,
    // BImg,
    // BForm,
    BFormGroup,
    // BFormSelect,
    // BFormTextarea,
    BButton,
    BSpinner,
    flatPickr,
    // BOverlay,
  },
  directives: {
    Ripple,
  },
  props: ['masterBiodata'],
  data() {
    return {
      localBiodata: null,
      localPermohonan: null,
      tanggalBerakhir: null,
      btnSimpanSpinnerShow: false,
      btnSimpanDisabled: false,
    }
  },
  async mounted() {
    emitter.off(
      'PERMOHONAN_UPDATE_TANGGAL_AKHIR',
      this.onPermohonanUpdateTanggalAkhir,
    )
    emitter.on(
      'PERMOHONAN_UPDATE_TANGGAL_AKHIR',
      this.onPermohonanUpdateTanggalAkhir,
    )
  },
  methods: {
    cancel() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-tanggal-akhir')
    },
    onPermohonanUpdateTanggalAkhir(e) {
      this.localBiodata = e.masterBiodata
      this.localPermohonan = e.masterPermohonan
      this.tanggalBerakhir = this.localBiodata.tanggal_berakhir
      // console.log('SIDEBAR AKHIR')
      // console.log(e)
      this.$root.$emit('bv::toggle::collapse', 'sidebar-tanggal-akhir')
    },
    async simpan() {
      this.btnSimpanSpinnerShow = true
      this.btnSimpanDisabled = true
      // await new Promise(r => setTimeout(r, 2000))
      try {
        await api().patch('keanggotaan/nia', {
          id: this.localBiodata.id,
          tanggal_berakhir: this.tanggalBerakhir,
        })
      } catch (error) {
        this.makeToast('danger', 'Error', error.response.data.message, true)
        this.btnSimpanSpinnerShow = false
        this.btnSimpanDisabled = false
        return
      }
      const oldTanggal = this.localBiodata.tanggal_berakhir
      this.localBiodata.tanggal_berakhir = this.tanggalBerakhir

      try {
        const result = await api().patch('permohonan/status', {
          id: this.localPermohonan.id,
          log_permohonan: {
            status_id: 6, // menggunakan status_id 6 karena ternyata di db blm ada status_id utk update tanggal berakhir
            catatan: `Tanggal berakhir keanggotaan dirubah dari ${oldTanggal} menjadi ${this.tanggalBerakhir}`,
          },
        })
        emitter.emit('STATUS_PERMOHONAN_UPDATED', result.data)
      } catch (error) {
        alert(error)
        this.makeToast('danger', 'Error', error.response.data.message, true)
        this.btnSimpanSpinnerShow = false
        this.btnSimpanDisabled = false
        return
      }

      // emitter.emit('VERIFICATION_UPDATED', result.data)
      this.makeToast('success', 'Sukses', 'Data berhasil disimpan')
      this.btnSimpanSpinnerShow = false
      this.btnSimpanDisabled = false
      this.tanggalBerakhir = null
      this.$root.$emit('bv::toggle::collapse', 'sidebar-tanggal-akhir')
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        noAutoHide,
      })
    },
  },
}
</script>
